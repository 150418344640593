import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Page } from './Page';
import './ExperienceDetails.css';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { FindNearbyChannels } from '../../find_channels/FindNearbyChannels';
import { ThumbsDownSvg } from '../../svg/ThumbsDownSvg';
import { ThumbsUpSvg } from '../../svg/ThumbsUpSvg';

export const ExperienceDetails = ({ channelDetails, token, homepageCallback, backButtonFunction }) => {
  const title = channelDetails.name;
  const pages = channelDetails.pages;

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isReferencesVisible, setIsReferencesVisible] = useState(false);
  const [rating, setRating] = useState(channelDetails.rating);

  const [socket, setSocket] = useState(null);

  // Set up socket.
  useEffect(() => {
    const socketForChannel = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
      query: { channel_uuid: channelDetails.id, token: token },
    });
    socketForChannel.on('connect', () => {
      console.log('Socket connected');
    });

    setSocket(socketForChannel);
    return () => {
      socketForChannel.disconnect();
    };
  }, [channelDetails.id, channelDetails.end_time, token]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [currentPageIndex]);

  // send customer interactions to the server
  const handleEvent = ({ eventType, payload }) => {
    socket.emit('experience_event', {
      token: token,
      channel_id: channelDetails.id,
      event_type: eventType,
      payload: payload,
    });
  };

  const handleNextPage = () => {
    if (currentPageIndex < pages.length - 1) {
      handleEvent({ eventType: 'channel-next-page', payload: `current_page_${currentPageIndex}` });
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  const handlePrevPage = () => {
    handleEvent({ eventType: 'channel-previous-page', payload: `current_page_${currentPageIndex}` });
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    } else {
      backButtonFunction();
    }
  };

  const toggleReferencesVisibility = () => {
    handleEvent({ eventType: 'channel-toggle-reference', payload: `${isReferencesVisible}` });
    setIsReferencesVisible(!isReferencesVisible);
  };

  const handleRating = newRating => {
    handleEvent({ eventType: 'channel-rating', payload: `${newRating}` });
    setRating(newRating);
  };

  const currentPage = pages[currentPageIndex];

  return (
    <div className='experience-container'>
      <Page
        key={currentPageIndex}
        title={title}
        page={currentPage}
        handleEvent={handleEvent}
        pageIndex={currentPageIndex}
        location={channelDetails.location}
      />
      {currentPageIndex === pages.length - 1 && (
        <ResponsiveBox
          children={[
            {
              leftChild: (
                <div className='references'>
                  {channelDetails.references.length > 0 && (
                    <div>
                      <button onClick={toggleReferencesVisibility} className='references-toggle' type='button'>
                        {isReferencesVisible ? '▲ References' : '> References'}
                      </button>
                      {isReferencesVisible && (
                        <ol>
                          {channelDetails.references.map((ref, index) => (
                            <li key={index}>
                              <a
                                href={
                                  !(ref.url.startsWith('http://') || ref.url.startsWith('https://'))
                                    ? `https://${ref.url}`
                                    : ref.url
                                }
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                {ref.text}
                              </a>
                            </li>
                          ))}
                        </ol>
                      )}
                    </div>
                  )}
                </div>
              ),
            },
            {
              leftChild: (
                <div className='rating' style={{ marginBottom: '20px' }}>
                  <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Rate and continue exploring</div>
                  <button
                    className={`thumb-button ${rating === 'up' ? 'selected' : ''}`}
                    onClick={() => handleRating('up')}
                    type='button'
                  >
                    <ThumbsUpSvg
                      dimension='40'
                      format={rating === 'up' ? 'bold' : rating === 'down' ? 'fade' : 'normal'}
                    />
                  </button>
                  <button
                    className={`thumb-button ${rating === 'down' ? 'selected' : ''}`}
                    onClick={() => handleRating('down')}
                    type='button'
                  >
                    <ThumbsDownSvg
                      dimension='40'
                      format={rating === 'down' ? 'bold' : rating === 'up' ? 'fade' : 'normal'}
                    />
                  </button>
                </div>
              ),
            },
            {
              leftChild: (
                <FindNearbyChannels
                  homepageCallback={homepageCallback}
                  token={token}
                  buttonText='LookAround again'
                  disabled={rating === null}
                  disabledClickMessage='Rate to activate'
                />
              ),
            },
          ]}
          isSnug={true}
        />
      )}
      <div
        className='navigation'
        style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '100px', paddingTop: '10px' }}
      >
        <button className='nav-button prev-button' onClick={handlePrevPage} type='button'>
          &#9664;
        </button>
        {currentPageIndex < pages.length - 1 && (
          <button className='nav-button next-button' onClick={handleNextPage} type='button'>
            &#9654;
          </button>
        )}
      </div>
    </div>
  );
};
