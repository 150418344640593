import React from 'react';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { LookAroundNavbar } from '../navbar/LookAroundNavbar';

export const TermsOfService = () => {
  return (
    <div>
      <LookAroundNavbar />
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        <ResponsiveBox
          isSnug={true}
          children={[
            {
              leftChild: (
                <div>
                  <h1>Terms of service</h1>
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <h2>Consumer Data Privacy Policy</h2>
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  At LookAround, we respect and protect the privacy of our users. This policy outlines how we collect,
                  use, store, and share consumer data, including personal and payment information.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Information Collection:</b> We collect the minimum information necessary to provide great service
                  to our customers. This includes email address, and login information for account creation, payment
                  details for premium features and location information needed to find and create channels. We clearly
                  highlight every time location information is requested.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Use of Information:</b> Information collected is used solely for service delivery, payment
                  processing, and communication with our users about their accounts or updates to our service.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Data Sharing:</b> We do not sell or rent personal information to third parties. Information may be
                  shared with Stripe for payment processing and as required by law.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Data Protection:</b> We employ a variety of security measures to protect personal information,
                  including encryption and access controls.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>User Rights:</b> Users have the right to access, correct, or delete their personal information held
                  by us. Contact us at <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a> to exercise
                  these rights.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Updates to Privacy Policy:</b> We may update this policy to reflect changes to our information
                  practices. We will notify users of any significant changes.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <h2>Refund and Dispute Policy</h2>
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  At LookAround, we strive to ensure satisfaction with our software service. This policy outlines our
                  refund and dispute resolution procedures.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Refund Requests:</b> Users are able to cancel their monthly subscriptions at any time. The service
                  will continue to be available till the end of the month. Once purchased, for most users, the monthly
                  subscription fee is non-refundable. For special accommodation, contact us at
                  <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a> with your account information and
                  purchase details. Transactions will be processed in United States Dollar (USD).
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Dispute Resolution:</b> In the event of a dispute, we encourage users to contact us directly to
                  find a resolution. We will work with you to address your concerns and seek a mutually beneficial
                  outcome.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Chargebacks:</b> If a chargeback is initiated, we will investigate the transaction. Users are
                  encouraged to contact us before filing a chargeback to resolve any issues directly.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Contact Information:</b> For any questions or concerns regarding refunds or disputes, please
                  contact us at <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <h2>Security and Policy for Transmission of Payment Card Details</h2>
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  LookAround is committed to protecting the security of our customers' information, including payment
                  card details, during transmission and storage. We use Stripe as our payment processor to ensure the
                  highest standards of payment security.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Data Encryption:</b> All payment card details are encrypted using secure socket layer technology
                  (SSL) and then transmitted directly to Stripe's servers without passing through our servers. Stripe
                  also uses encryption to secure the data in transit and at rest.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Compliance:</b> Our website complies with the Payment Card Industry Data Security Standards (PCI
                  DSS) by leveraging Stripe's infrastructure. Stripe is certified as a PCI Level 1 Service Provider, the
                  most stringent level of certification available in the payments industry.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Monitoring and Response:</b> We continuously monitor our systems for potential security breaches
                  and strive to promptly address any security issues.
                </div>
              ),
            },
            {
              leftChild: (
                <div>
                  <b>Contact Information:</b> For any questions or concerns regarding our security practices, please
                  contact us at <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>.
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
