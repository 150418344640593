import React, { useState, useRef } from 'react';
// import heic2any from 'heic2any';
import { CustomButton } from '../../button/CustomButton';
import { putFile, s3SignedUrl } from '../../../api/s3';

export const UploadPicture = ({ homepageCallback, token, channelId, handleNewComment }) => {
  // See if all of these can be reduced by creating a new file object for HEIC.
  const [selectedFileBlob, setSelectedFileBlob] = useState(null);
  const [selectedFileSize, setSelectedFileSize] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [uploadFileStatus, setUploadFileStatus] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        throw new Error('HEIC/HEIF files not supported');
        // try {
        //   setUploadFileStatus('Converting to jpeg');
        //   const jpegBlob = await heic2any({
        //     blob: file,
        //     toType: 'image/jpeg',
        //     quality: 0.8, // JPEG quality (0.8 means 80% quality)
        //   });
        //   setUploadFileStatus(null);
        //   // Update the selected file to the converted PNG
        //   setSelectedFileType('image/jpeg');
        //   setSelectedFileName(file.name.replace(/\.(heic|heif)$/i, '.jpeg'));
        //   setSelectedFileSize(jpegBlob.size);
        //   setSelectedFileBlob(jpegBlob);
        // } catch (error) {
        //   console.error('Error converting HEIC/HEIF to PNG:', error);
        // }
      } else {
        // File is not in HEIC/HEIF format, update selected file directly
        setSelectedFileType(file.type);
        setSelectedFileName(file.name);
        setSelectedFileSize(file.size);
        setSelectedFileBlob(file);
      }
    }
  };

  const handleUpload = async () => {
    setUploadFileStatus('Verifying...');
    const sizeInMb = selectedFileSize / (1024 * 1024);
    if (sizeInMb > 10) {
      setUploadFileStatus('File is larger than 10mb.');
      return;
    }
    if (selectedFileName.length > 200) {
      setUploadFileStatus('Please select a shorter file name.');
      return;
    }
    setUploadFileStatus('Preparing...');
    s3SignedUrl(selectedFileName, selectedFileType, token).then(response => {
      if (!response.isOk) {
        throw new Error(`Error signing file ${response}.`);
      } else {
        setUploadFileStatus('Uploading..');
        putFile(response.signedUrl, selectedFileType, selectedFileBlob).then(putResponse => {
          if (putResponse.isOk) {
            setUploadFileStatus('Finishing up...');
            handleNewComment({
              token: token,
              channel_id: channelId,
              message: selectedFileName,
              filename: response.file_name,
            });
            setUploadFileStatus('');
            setSelectedFileBlob(null);
            setSelectedFileSize(null);
            setSelectedFileName(null);
            setSelectedFileType(null);
          } else {
            // Do something to denote failure.
            setUploadFileStatus('Something went wrong on our end. Please try refreshing.');
            console.log('Response not OK');
            console.log(putResponse);
          }
        });
      }
    });
  };

  return [
    {
      leftChild: (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
          <CustomButton
            onClickFunction={() => fileInputRef.current.click()}
            button_text={selectedFileName ? 'Change' : 'Add file'}
            type='tertiary'
            size='small'
          />
          <span style={{ marginLeft: '8px', fontSize: '10px' }}>{selectedFileName}</span>
        </div>
      ),
      rightChild: (
        <div>
          {selectedFileName && (
            <CustomButton onClickFunction={handleUpload} button_text='Upload' type='tertiary' size='small' />
          )}
          {uploadFileStatus && <span style={{ color: 'gray', fontSize: '10px' }}>{uploadFileStatus}</span>}
        </div>
      ),
    },
  ];
};
