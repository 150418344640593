const handleResponse = async response => {
  const statusCode = response.status;
  const isOk = response.ok;
  const responseData = await response.json();
  return { statusCode, isOk, ...responseData };
};

export const asyncPostRequest = async (itemData, endpoint) => {
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(itemData),
    });
    return handleResponse(response);
  } catch (error) {
    return { statusCode: 500, message: error.toString() };
  }
};

export const asyncPutRequest = async (url, fileType, blob) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': fileType,
      },
      body: blob,
    });
    return { statusCode: response.status, isOk: response.ok };
  } catch (error) {
    return { statusCode: 500, message: error.toString(), isOk: false };
  }
};

export const asyncGetRequest = async endpoint => {
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  } catch (error) {
    return { statusCode: 500, message: error.toString() };
  }
};
