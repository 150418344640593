import React from 'react';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { LookAroundNavbar } from '../navbar/LookAroundNavbar';

export const About = () => {
  return (
    <div>
      <LookAroundNavbar />
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        <ResponsiveBox
          isSnug={true}
          children={[
            {
              leftChild: <h1 style={{ marginTop: '15px' }}> About </h1>,
            },
            {
              leftChild: (
                <div>
                  <p>
                    LookAround helps you discover meaningful stories about places close to you (think within the block).
                  </p>
                  <p>
                    We believe in the joy of exploration. Are stories are hosted at particular locations and reveal
                    themselves only when you are close enough (about 50m or 150ft).
                  </p>
                  <p>
                    We are a small team of local San Francisco residents and take pride in the quality of experiences
                    that we provide for our users.
                  </p>
                  <p>
                    We find meaning in the smallest stories that help people call their neighborhoods home and would
                    love to work together to share your story. Drop us a note at{' '}
                    <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>.
                  </p>
                </div>
              ),
            },
            {
              leftChild: <h1 style={{ marginTop: '15px' }}> Service Area </h1>,
            },
            {
              leftChild: (
                <div>
                  <p>
                    We are currently live in San Francisco in five neighborhoods- North Beach, Russian Hill, Nob Hill,
                    Chinatown and Telegraph Hill. Reach out at{' '}
                    <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a> and tell us where to expand as we
                    grow.
                  </p>
                  <img
                    src='https://lookaround-live.s3.us-west-1.amazonaws.com/static/6_Coverage.jpg'
                    alt='Coverage map'
                    style={{ borderRadius: '8px', maxHeight: '350px', maxWidth: '350px' }}
                  />
                </div>
              ),
            },
            {
              leftChild: <h1 style={{ marginTop: '35px' }}> How it works </h1>,
            },
            {
              leftChild: (
                <div>
                  <p>With LookAround the most relevant stories about your neighborhood are just a few taps away.</p>
                  <ol>
                    <li>Sign in and tap 'LookAround'</li>
                    <li>Choose from a number of stories relevant to your block.</li>
                    <li>Once you uncover your block, move one over and LookAround again!</li>
                  </ol>
                  <p>
                    As you complete our stories (rate to mark them as complete), they get added to your timeline,
                    creating your personal storyboard!
                  </p>
                  <p>
                    We are always reachable for feedback and suggestions at{' '}
                    <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>
                  </p>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
