import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const ImageCarousel = ({ images }) => {
  return (
    <div style={{ width: '90%', margin: '0 auto' }}>
      <Carousel
        showStatus={false} // Hide the image count "1 of n"
        showThumbs={images.length === 1 ? false : true} // Hide the thumbnails
        showIndicators={false} // Hide the indicator dots
        showArrows={true} // Show navigation arrows
        infiniteLoop={true}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image.url} alt={image.text} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
