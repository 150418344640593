import React from 'react';
import './ProgressBar.css';

export const ProgressBar = ({ steps, currentStep, fractionComplete = 0.5 }) => {
  if (steps.length !== 3 || currentStep < 0 || currentStep > 2) {
    throw new Error(`Unexpected input: ${steps}, ${currentStep}`);
  }
  // bar from step 0 to 1 is from 84% to 51%
  // bar from step 1 to 2 is from 47% to 16%
  return (
    <div className='progress-bar-container'>
      <div className='progress-line-full' />
      {steps.map((step, index) => (
        <div key={index} className='step-container'>
          <div
            className='circle'
            style={{
              backgroundColor: index <= currentStep ? '#026440' : '#ccc',
            }}
          />
          <div className='label'>{step.label}</div>
          <div className='subtext' style={{ color: '#1565C0' }}>
            {step.subtext}
          </div>
        </div>
      ))}
      <div
        className='progress-line-completed'
        style={{
          right:
            currentStep === 0
              ? `${84 - fractionComplete * 33}%`
              : currentStep === 1
                ? `${48 - fractionComplete * 33}%`
                : '16%',
        }}
      />
    </div>
  );
};
