import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CardCarousel.css';
import { CustomButton } from '../button/CustomButton';
import PropTypes from 'prop-types';

export const CardCarousel = ({ cards }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10%',
    arrows: false,
  };
  return (
    <div className='card-carousel-wrapper custom-card-carousel'>
      <div className='card-carousel'>
        <Slider {...sliderSettings}>
          {cards.map((card, idx) => (
            <div key={idx} className='carousel-slide'>
              <div className={`slide-inner ${!card.image ? 'no-image' : ''}`}>
                {card.image ? <img src={card.image} alt={card.channelName} className='slide-image' /> : null}
                <div className='slide-content'>
                  <h2 className='slide-title'>{card.channelName}</h2>
                  <p className='slide-description'>{card.description}</p>
                  {!card.image && (
                    <CustomButton
                      // size='small'
                      type='accent'
                      onClickFunction={() => {
                        window.open(
                          `https://maps.google.com/?q=${card.latitude},${card.longitude}`,
                          '_blank',
                          'noopener,noreferrer'
                        );
                      }}
                      button_text='Navigate'
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

CardCarousel.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string, // ex. 'https://lookaround-live.s3.us-west-1.amazonaws.com/static/1_MarkHopkins.jpg'
      channelName: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    })
  ).isRequired,
};
