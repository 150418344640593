import React from 'react';
import { ProgressBar } from '../../library/progress_bar/PorgressBar';

const getLevels = ({ userExperienceCount, totalExperienceCount }) => {
  // TODO: Consider serving these messages from the backend
  const remaining = totalExperienceCount - userExperienceCount;
  const explorationMessage = `This area is teeming with local gems. Keep exploring to discover ${remaining} more`;
  return [
    {
      name: 'New to town',
      experienceCount: 0,
      afterCompletionLandingMessage: 'Level 1 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'Cable Car surfer',
      experienceCount: 1,
      afterCompletionLandingMessage: 'Level 2 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'Beat poet',
      experienceCount: 5,
      afterCompletionLandingMessage: 'Level 3 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'Local guide',
      experienceCount: 10,
      afterCompletionLandingMessage: 'Level 4 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'Wild parrot',
      experienceCount: 20,
      afterCompletionLandingMessage: 'Level 5 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'Nawab of Nob Hill',
      experienceCount: 40,
      afterCompletionLandingMessage: 'Level 6 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'San Francisco Spirit', // Spirit of SF
      experienceCount: 60,
      afterCompletionLandingMessage: 'Level 7 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'Lombard Legend',
      experienceCount: 85,
      afterCompletionLandingMessage: 'Level 8 completed',
      afterCompletionExplorationMessage: explorationMessage,
    },
    {
      name: 'True 49er',
      experienceCount: { totalExperienceCount },
      afterCompletionLandingMessage: '',
      afterCompletionExplorationMessage: explorationMessage,
    },
  ];
};

export const LandingPageProgressBar = ({ userExperienceCount, totalExperienceCount }) => {
  const levels = getLevels({
    totalExperienceCount: totalExperienceCount,
    userExperienceCount: userExperienceCount,
  });
  if (userExperienceCount >= totalExperienceCount) {
    return (
      <div style={{ marginTop: '40px', marginBottom: '10px' }}>
        <div
          style={{
            marginBottom: '25px',
            fontSize: '17px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingLeft: '50px',
            paddingRight: '50px',
            textAlign: 'center',
          }}
        >
          Tell us how we did at <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>
        </div>
        <ProgressBar
          steps={[
            { label: levels[levels.length - 3].name },
            { label: levels[levels.length - 2].name },
            { label: levels[levels.length - 1].name },
          ]}
          currentStep={2}
        />
      </div>
    );
  }
  const nextIndex = levels.findIndex(level => level.experienceCount > userExperienceCount);
  const currentIndex = nextIndex - 1;
  const levelsToReturn =
    currentIndex % 2 === 0
      ? [currentIndex, currentIndex + 1, currentIndex + 2]
      : [currentIndex - 1, currentIndex, currentIndex + 1];
  const fractionComplete =
    (userExperienceCount - levels[currentIndex].experienceCount) /
    (levels[nextIndex].experienceCount - levels[currentIndex].experienceCount);
  return (
    <div style={{ marginTop: '40px', marginBottom: '10px' }}>
      <div
        style={{
          marginBottom: '25px',
          fontSize: '17px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          paddingLeft: '50px',
          paddingRight: '50px',
          textAlign: 'center',
        }}
      >
        Complete {levels[nextIndex].experienceCount - userExperienceCount} more{' '}
        {levels[nextIndex].experienceCount - userExperienceCount === 1 ? 'experience' : 'experiences'} to unlock the
        next level!
        {/* {levels[currentIndex].afterCompletionLandingMessage} */}
      </div>
      <ProgressBar
        steps={[
          { label: levels[levelsToReturn[0]].name },
          { label: levels[levelsToReturn[1]].name },
          { label: levels[levelsToReturn[2]].name },
        ]}
        currentStep={currentIndex % 2}
        fractionComplete={fractionComplete}
      />
    </div>
  );
};

export const LookAroundPageProgressBar = ({ userExperienceCount, totalExperienceCount, serviceType }) => {
  const levels = getLevels({
    totalExperienceCount: totalExperienceCount,
    userExperienceCount: userExperienceCount,
  });
  if (userExperienceCount >= totalExperienceCount) {
    return (
      <div style={{ marginTop: '40px', marginBottom: '40px' }}>
        <h1 style={{ fontWeight: 'bold', paddingLeft: '20px' }}> Awards</h1>
        <h4
          style={{
            marginBottom: '40px',
            display: 'flex',
            justifyContent: 'left',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          Tell us how we did at <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>
        </h4>
        <ProgressBar
          steps={[
            { label: levels[levels.length - 3].name },
            { label: levels[levels.length - 2].name },
            { label: levels[levels.length - 1].name },
          ]}
          currentStep={2}
        />
      </div>
    );
  }
  const nextIndex = levels.findIndex(level => level.experienceCount > userExperienceCount);
  const currentIndex = nextIndex - 1;
  const experienceCountToNextLevel = levels[nextIndex].experienceCount - userExperienceCount;
  const fractionComplete =
    (userExperienceCount - levels[currentIndex].experienceCount) /
    (levels[nextIndex].experienceCount - levels[currentIndex].experienceCount);
  const levelsToReturn =
    currentIndex % 2 === 0
      ? [currentIndex, currentIndex + 1, currentIndex + 2]
      : [currentIndex - 1, currentIndex, currentIndex + 1];

  const remainingStories = totalExperienceCount - userExperienceCount;
  const copyForCount =
    remainingStories >= 100
      ? 'over 100'
      : remainingStories >= 25
        ? 'dozens of'
        : remainingStories >= 20
          ? 'over 20'
          : remainingStories >= 10
            ? 'over 10'
            : remainingStories >= 5
              ? 'a handful of'
              : 'a few';

  return (
    <div style={{ marginTop: '40px', marginBottom: '40px' }}>
      <h1 style={{ fontWeight: 'bold', paddingLeft: '20px' }}> Awards</h1>
      <h4
        style={{
          marginBottom: '40px',
          display: 'flex',
          justifyContent: 'left',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        Keep exploring. There are {copyForCount} stories {serviceType === 'in' ? 'within a mile.' : 'to go.'}
      </h4>
      <ProgressBar
        steps={[
          { label: levels[levelsToReturn[0]].name },
          {
            label: levels[levelsToReturn[1]].name,
            subtext: currentIndex % 2 === 0 ? `${experienceCountToNextLevel} to go!` : '',
          },
          {
            label: levels[levelsToReturn[2]].name,
            subtext: currentIndex % 2 === 1 ? `${experienceCountToNextLevel} to go!` : '',
          },
        ]}
        currentStep={currentIndex % 2}
        fractionComplete={fractionComplete}
      />
    </div>
  );
};
