import { utc_time_string_to_hr_min_local_time_string } from '../../utilities';

export const getCommentsSection = (comments, channelDetails) => {
  const getCommentDetails = ({ user_name, timestamp, message, url }) => {
    let content;
    if (url) {
      if (['jpg', 'jpeg', 'png', 'heic', 'webp'].indexOf(url.split('.').pop().toLowerCase()) !== -1) {
        content = <img src={url} alt={message} style={{ maxWidth: '95%' }} />;
      } else {
        content = <a href={url}>{message}</a>;
      }
    } else {
      content = message;
    }
    return [
      {
        leftChild: <div style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}>{user_name}</div>,
        rightChild: (
          <div style={{ fontSize: '12px', color: '#808080', marginTop: '10px' }}>
            at {utc_time_string_to_hr_min_local_time_string(timestamp)}
          </div>
        ),
        leftWidth: '70%',
        rightWidth: '30%',
        width: '100%',
      },
      {
        leftChild: <div style={{ fontSize: '14px', width: '100%', margin: '0 auto' }}>{content}</div>,
        alignNaturally: true,
      },
    ];
  };

  const children = [];
  if (comments.length === 0) {
    children.push({
      leftChild: (
        <div style={{ fontSize: '16px', paddingTop: '10px', textAlign: 'center' }}>
          {['curated_public', 'curated_in_house'].includes(channelDetails.channel_type) ? '' : 'No comments yet'}
        </div>
      ),
    });
  } else {
    for (let i = 0; i < comments.length; i++) {
      children.push(...getCommentDetails(comments[i]));
    }
  }
  return children;
};
