import { asyncPostRequest } from './requests';

export const login = async token => {
  return await asyncPostRequest({ token: token }, '/auth/login');
};

export const signout = async token => {
  return await asyncPostRequest({ token: token }, '/auth/sign_out');
};

export const sendEmail = async token => {
  return await asyncPostRequest({ token: token }, '/auth/test_email');
};
