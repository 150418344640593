import { asyncPostRequest, asyncPutRequest } from './requests';

export const s3SignedUrl = async (filename, filetype, token) => {
  return await asyncPostRequest(
    {
      filename: filename,
      filetype: filetype,
      token: token,
    },
    '/s3/sign_s3'
  );
};

export const putFile = async (url, fileType, blob) => {
  return await asyncPutRequest(url, fileType, blob);
};
