export const utc_time_string_to_local_time_string = timestamp => {
  const date = utc_time_string_spoch_seconds_to_date(timestamp);
  const currentDate = new Date();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const year = date.getFullYear();
  const hour12 = hour >= 12 ? 'PM' : 'AM';

  if (Math.abs(currentDate - date) >= 180 * 24 * 60 * 60 * 1000) {
    // If date is more than a year old, format as "YYYY, Mon DD, hh:mm[am/pm]"
    return `${hour % 12 || 12}:${minute < 10 ? '0' : ''}${minute} ${hour12} ${month} ${day}, ${year}`;
  } else {
    // Otherwise, format as "Mon DD, hh:mm[am/pm]"
    return `${hour % 12 || 12}:${minute < 10 ? '0' : ''}${minute} ${hour12} ${month} ${day}`;
  }
};

export const utc_time_string_to_hr_min_local_time_string = timestamp_epoch_seconds => {
  const date = utc_time_string_spoch_seconds_to_date(timestamp_epoch_seconds);
  const hour = date.getHours();
  const minute = date.getMinutes();
  const hour12 = hour >= 12 ? 'PM' : 'AM';
  return `${hour % 12 || 12}:${minute < 10 ? '0' : ''}${minute} ${hour12}`;
};

export const utc_time_string_spoch_seconds_to_date = timestamp_epoch_seconds => {
  return new Date(timestamp_epoch_seconds * 1000);
};

export const distance_between_locations = (lat1, lon1, lat2, lon2) => {
  const R = 6371000; // Earth's radius in meters

  // Convert degrees to radians
  const toRadians = degrees => degrees * (Math.PI / 180);

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
};
