import React from 'react';

export const CircleRadiusSvg = ({ dimension = '24' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 28 28'
      width={dimension}
      height={dimension}
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='icon radius-icon'
      style={{ marginRight: '1px', verticalAlign: 'middle' }}
    >
      <circle cx='12' cy='12' r='10'></circle>
      <line x1='12' y1='12' x2='22' y2='12' />
      <circle cx='12' cy='12' r='1' fill='currentColor' />
    </svg>
  );
};
