import React, { useState } from 'react';
import './Tile.css';
import { fetchExperienceDetails } from '../../../api/channel_api';

export const Tile = ({
  title,
  image,
  description,
  channelId,
  homepageCallback,
  token,
  isPremium,
  latitude,
  longitude,
  size = 'big',
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // Used only when details of the tile cannot be
  // fetched and location is also not available.
  const [showMessage, setShowMessage] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    fetchExperienceDetails(token, channelId).then(details => {
      if (details.statusCode === 400 || details.statusCode === 404) {
        setIsLoading(false);
        homepageCallback({ callbackType: 'signout', payload: {} });
      } else if (details.statusCode === 200) {
        setIsLoading(false);
        homepageCallback({ callbackType: 'channelDetails', payload: details });
      } else {
        setIsLoading(false);
        throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
      }
    });
  };

  return (
    <div
      className={`tile ${size} ${!description ? 'no-description' : ''} ${showMessage ? 'grayed-out' : ''}`}
      onClick={() => {
        if (channelId) {
          // Proceed to the details of the channel ID
          handleClick();
        } else if (latitude) {
          // Open a focused view of the channel.
          homepageCallback({
            callbackType: 'navigateToChannel',
            payload: { title, image, description, latitude, longitude },
          });
          window.open(`https://maps.google.com/?q=${latitude},${longitude}`, '_blank', 'noopener,noreferrer');
        } else {
          setShowMessage(true);
          setTimeout(() => setShowMessage(false), 2000); // Show the message for 2 seconds
        } // else briefly gray out the tile and show the text 'Sign up to get started, it's free!'
      }}
    >
      {isLoading ? (
        <div className='loading'>Loading...</div>
      ) : (
        <>
          <div className='image-container'>
            <img src={image} alt={title} />
          </div>
          {showMessage && <div className='message'>Sign in to get started</div>}
          {latitude && <div className='premium-badge'>NAVIGATE</div>}
          {!latitude && isPremium && <div className='premium-badge'>TOP PICK</div>}
          <div className='content'>
            <div className='title'>{title}</div>
            {description && <div className='description'>{description}</div>}
          </div>
        </>
      )}
    </div>
  );
};
