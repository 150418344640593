import React from 'react';
import './SendButton.css'; // Import CSS file for styling

export const SendButton = ({ onClick }) => {
  return (
    <button className='submit-button' onClick={onClick}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 12' // Adjusted viewBox to reduce height while maintaining width
        width='24'
        height='12' // Set the height to match the viewBox
        fill='none'
        stroke='currentColor' // Black color for the arrow
        strokeWidth='1.5' // Reduced stroke width to 1.5
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M7 6h11M14 1l6 5-6 5'></path> {/* Adjusted coordinates */}
      </svg>
    </button>
  );
};
