import React, { useState } from 'react';
import { CustomButton } from '../button/CustomButton';
import { LocationError, getGeolocation } from '../../api/location';
import { getPublicExperiences } from '../../api/channel_api';

export const FindNearbyPublicChannels = ({ homepageCallback, buttonText = 'LookAround', handleUserEvent }) => {
  const [findChannelsStatus, setFindChannelsStatus] = useState('initial');

  const getChannels = async (latitude, longitude) => {
    getPublicExperiences(latitude, longitude).then(channelResponse => {
      if (channelResponse.statusCode === 200) {
        setFindChannelsStatus('lookaround_success');
        homepageCallback({
          callbackType: 'lookaroundPublic',
          payload: {
            local: channelResponse.channels,
            nearby: channelResponse.channels_nearby,
            serviceType: channelResponse.service_type,
          },
        });
      } else {
        setFindChannelsStatus('error_channels');
      }
    });
  };

  const retrieveLocationAndGetChannels = () => {
    setFindChannelsStatus('loading_location');
    getGeolocation()
      .then(locationResponse => {
        if (locationResponse.status === 'success') {
          setFindChannelsStatus('loading_channels');
          getChannels(locationResponse.latitude, locationResponse.longitude);
        } else {
          handleUserEvent({
            eventType: 'error_location',
            payload: '',
          });
          setFindChannelsStatus('error_location');
        }
      })
      .catch(error => {
        handleUserEvent({
          eventType: 'error_location',
          payload: '',
        });
        setFindChannelsStatus('error_location');
      });
  };

  return (
    <div id='lookAroundDiv' style={{ display: 'flex', justifyContent: 'center' }}>
      {findChannelsStatus === 'loading_location' && (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
          Looking around... might take a moment
        </div>
      )}
      {(findChannelsStatus === 'initial' || findChannelsStatus === 'lookaround_success') && (
        <CustomButton
          onClickFunction={retrieveLocationAndGetChannels}
          button_text={buttonText}
          backgroundColor='#2E8B57'
          size='large'
        />
      )}
      {findChannelsStatus === 'error_location' && (
        <div style={{ color: 'maroon', fontSize: '12px', textAlign: 'center' }}>
          <LocationError />
        </div>
      )}
      {findChannelsStatus === 'error_channels' && (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15, textAlign: 'center' }}>
          Unable to find channel, please try reloading the page.{' '}
        </div>
      )}
      {findChannelsStatus === 'loading_channels' && (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>Taking you to the channels. </div>
      )}
    </div>
  );
};
