import React, { useState } from 'react';
import { fetchExperienceDetails } from '../../api/channel_api';

export const ChannelNameButton = ({ homepageCallback, channel, token }) => {
  const [retrieveChannelDetailsStatus, setRetrieveChannelDetailsStatus] = useState('initial');

  return (
    <div>
      {retrieveChannelDetailsStatus === 'initial' && (
        <button
          style={{
            background: 'none',
            color: '#1565C0',
            border: 'none',
            padding: '0',
            font: 'inherit',
            cursor: 'pointer',
            textAlign: 'left',
            fontWeight: 700,
          }}
          onClick={() => {
            setRetrieveChannelDetailsStatus('loading');
            fetchExperienceDetails(token, channel.channel_id).then(details => {
              if (details.statusCode === 400 || details.statusCode === 404) {
                homepageCallback({ callbackType: 'signout', payload: {} });
              } else if (details.statusCode === 200) {
                homepageCallback({ callbackType: 'channelDetails', payload: details });
              } else {
                throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
              }
            });
            setRetrieveChannelDetailsStatus('success');
          }}
        >
          {channel.thumbnail_url ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={channel.thumbnail_url}
                alt='Thumbnail'
                style={{ maxWidth: '20%', marginRight: '10px', borderRadius: '4px', border: '1px solid gray' }}
              />
              <span>{channel.channel_name} </span>
            </div>
          ) : (
            <div> {channel.channel_name} </div>
          )}
        </button>
      )}
      {retrieveChannelDetailsStatus === 'loading' && <div>Looking up the channel...</div>}
      {retrieveChannelDetailsStatus === 'success' && <div>Taking you to channel details...</div>}
      {retrieveChannelDetailsStatus === 'error' && <div>Error loading channel</div>}
    </div>
  );
};
