import * as React from 'react';

// Alternative resources:
// https://docs.stripe.com/billing/subscriptions/build-subscriptions?platform=react-native
// https://docs.stripe.com/payments/quickstart
// https://docs.stripe.com/api/payment_intents/object

export const PricingPage = ({ customerEmail }) => {
  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY}
      customer-email={customerEmail}
    ></stripe-pricing-table>
  );
};
