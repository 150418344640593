import React from 'react';
import { ReactComponent as BackArrowSvg } from './back-arrow.svg';

export const Header = ({ title, onClickFunction, backgroundColor = '#f0f0f0' }) => {
  const styles = {
    container: {
      display: 'flex', // Enables flexbox
      alignItems: 'center', // Vertically centers the flex items
      justifyContent: 'center', // Horizontally centers the content
      position: 'relative', // Allows absolute positioning within the container
      height: '45px', // Set a height for your header
      backgroundColor: backgroundColor, // Optional: background color #f4f9f9 #e0e0e0
    },
    button: {
      background: 'none', // Removes default background
      border: 'none', // Removes default border
      cursor: 'pointer', // Changes cursor to pointer to indicate it's clickable
      padding: 0, // Removes padding
      outline: 'none', // Removes outline on focus (for accessibility, you might want to handle focus differently)
      position: 'absolute', // Ensures the button doesn't affect centering of title
      left: '10px', // Space from the left edge
    },
    icon: {
      width: '24px', // Size of the icon
      height: '24px', // Size of the icon
    },
    title: {
      fontSize: '20px', // Font size of the title
      fontWeight: 'bold', // Optional: makes the font bold
    },
  };

  return (
    <div style={styles.container}>
      <button onClick={onClickFunction} style={styles.button}>
        <BackArrowSvg style={styles.icon} />
      </button>
      {/* <SendButton onClick={() => {}} /> */}
      <div style={styles.title}>{title}</div>
    </div>
  );
};
