import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { CustomButton } from '../button/CustomButton';
import { PanelForCreators } from './components/PanelForCreator';
import { getCommentsSection } from './components/CommentsSection';
import { getNewParticipationSection } from './components/NewParticipationSection';
import { PrivacyNutritionLabel } from '../button/privacy_nutrition_label/PrivacyNutritionLabel';
import { fetchExperienceDetails } from '../../api/channel_api';
import { Header } from '../header/Header';
import { ImageCarousel } from './components/ImageCarousel';

export const ChannelDetails = ({ channelDetails, token, homepageCallback, backButtonFunction }) => {
  const [comments, setComments] = useState(channelDetails.comments);
  const [endTime, setEndTime] = useState(channelDetails.end_time);
  const [socket, setSocket] = useState(null);

  // Set up socket.
  useEffect(() => {
    const socketForChannel = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
      query: { channel_uuid: channelDetails.id, token: token },
    });
    setSocket(socketForChannel);
    socketForChannel.on('new_comment_broadcast', newComment => {
      setComments(prevComments => [...prevComments, newComment]);
    });
    socketForChannel.on('close_channel', ending_time => {
      setEndTime(ending_time);
    });
    return () => {
      socketForChannel.disconnect();
    };
  }, [channelDetails.id, channelDetails.end_time, token]);

  // Handle adding new comment and emit to server via WebSocket
  const handleNewComment = newComment => {
    socket.emit('new_comment', newComment);
  };

  // Define the view.
  const channelDetailsChildren = [
    {
      leftChild: <Header title={channelDetails.name} onClickFunction={backButtonFunction} />,
    },
    {
      leftChild: (
        <div>
          {['curated_public', 'curated_in_house'].includes(channelDetails.channel_type) ? (
            ''
          ) : (
            <div style={{ marginTop: '5px', textAlign: 'center', fontStyle: 'italic' }}>
              -by {channelDetails.creator}
            </div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px' }}>
          <ImageCarousel images={channelDetails.images} />
        </div>
      ),
    },
    {
      leftChild: (
        <div>
          {channelDetails.description ? (
            <div style={{ textAlign: 'center' }}>
              {['curated_public', 'curated_in_house'].includes(channelDetails.channel_type)
                ? ''
                : 'Channel description:'}{' '}
              {channelDetails.description}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div
          style={{
            display: 'flex', // Enables flexbox
            alignItems: 'center', // Vertically centers the flex items
            justifyContent: 'center', // Horizontally centers the content
            marginTop: '10px',
          }}
        >
          {channelDetails.location ? (
            <div>
              <CustomButton
                size='small'
                type='tertiary'
                onClickFunction={() => {
                  window.open(
                    `https://maps.google.com/?q=${channelDetails.location.latitude},${channelDetails.location.longitude}`,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                button_text='Navigate'
              />{' '}
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
          {channelDetails.references && channelDetails.references.length > 0 ? (
            <div>
              <b>References:</b>
              <ol>
                {channelDetails.references.map((resource, index) => (
                  <li key={index}>
                    <a href={resource.url}>{resource.text}</a>
                  </li>
                ))}
              </ol>
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];

  // Start with a panel for channel creators.
  if (channelDetails.user_is_creator) {
    channelDetailsChildren.push({
      leftChild: <PanelForCreators channelDetails={channelDetails} token={token} homepageCallback={homepageCallback} />,
    });
  }
  // The privacy nutrition label
  if (channelDetails.channel_type !== 'curated_public' && channelDetails.channel_type !== 'curated_in_house') {
    channelDetailsChildren.push({
      leftChild: (
        <div style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}>
          <PrivacyNutritionLabel channelDetails={channelDetails} endTime={endTime} />
        </div>
      ),
    });
  }
  // 5. Comments.
  channelDetailsChildren.push(...getCommentsSection(comments, channelDetails));
  // 6. Add new comments/photos.
  channelDetailsChildren.push(
    ...getNewParticipationSection({ homepageCallback, token, channelDetails, handleNewComment })
  );

  return <ResponsiveBox isSnug={true} children={channelDetailsChildren} />;
};

export const RefreshChannelDetails = ({ token, channelDetails, homepageCallback }) => {
  return (
    <div>
      <CustomButton
        onClickFunction={() => {
          // This line is meant to re-mount the channel details view so that the web socket is refreshed.
          homepageCallback({ callbackType: 'loading', payload: '' });
          // Since the user has alredy participated, the passkey should not be needed.
          fetchExperienceDetails(token, channelDetails.id).then(details => {
            if (details.statusCode === 400 || details.statusCode === 404) {
              homepageCallback({ callbackType: 'signout', payload: {} });
            } else if (details.statusCode === 200) {
              homepageCallback({ callbackType: 'channelDetails', payload: details });
            } else {
              throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
            }
          });
        }}
        button_text='Refresh'
        type='tertiary'
        size='small'
      />
    </div>
  );
};
