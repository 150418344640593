import React, { useState, useEffect } from 'react';

export const CyclingText = ({ options, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % options.length);
    }, interval);

    return () => clearInterval(timer);
  }, [options, interval]);

  return <span>{options[currentIndex]}</span>;
};
