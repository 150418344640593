import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

// Remove default icon URLs to avoid broken image links
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

const MapInitializer = ({ initialPolygons, initialPoints, featureGroupRef }) => {
  const map = useMap();
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      for (const polygon of initialPolygons) {
        L.polygon(polygon.coordinates).addTo(featureGroupRef.current);
      }
      for (const point of initialPoints) {
        const circle = L.circle(point.coordinates, { radius: 50 }).addTo(featureGroupRef.current);
        circle.bindPopup(point.name); // Bind popup with point.name
      }

      featureGroupRef.current.addTo(map);
      initializedRef.current = true;
    }
  }, [initialPolygons, initialPoints, map, featureGroupRef]);

  return null;
};

export const MapExperiencesComponent = ({
  initialPolygons,
  initialPoints = [
    {
      name: 'Experience 1',
      coordinates: [
        {
          lat: 37.798244,
          lng: -122.413573,
        },
      ],
    },
  ],
}) => {
  const mapCenter =
    initialPolygons.length === 0 ? { lat: 37.80084, lng: -122.40986 } : initialPolygons[0]['coordinates'][0];
  const [center] = useState(mapCenter);
  const ZOOM_LEVEL = 15;
  const featureGroupRef = useRef(L.featureGroup());

  return (
    <div style={{ height: '60vh', width: '100%' }}>
      <MapContainer center={center} zoom={ZOOM_LEVEL} style={{ height: '100%' }}>
        <MapInitializer
          initialPolygons={initialPolygons}
          featureGroupRef={featureGroupRef}
          initialPoints={initialPoints}
        />
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </MapContainer>
    </div>
  );
};

MapExperiencesComponent.propTypes = {
  initialPolygons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  initialPoints: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      coordinates: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
