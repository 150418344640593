import React, { useState } from 'react';
import { CustomButton } from '../button/CustomButton';
import { updateChannelLocation } from '../../api/channel_api';
import { getGeolocation } from '../../api/location';

export const RelocateChannel = ({ token, channelId }) => {
  const [relocateChannelsStatus, setRelocateChannelsStatus] = useState('initial');

  const relocateChannel = async () => {
    await getGeolocation().then(locationQueryResult => {
      if (locationQueryResult.status === 'success') {
        updateChannelLocation(
          token,
          channelId,
          locationQueryResult.latitude,
          locationQueryResult.longitude,
          locationQueryResult.accuracy_meters
        ).then(response => {
          if (response.statusCode === 200) {
            setRelocateChannelsStatus('success');
            setTimeout(() => {
              setRelocateChannelsStatus('initial');
            }, 3000); // in milliseconds.
          } else {
            setRelocateChannelsStatus('error');
          }
        });
      } else {
        setRelocateChannelsStatus('error');
        setTimeout(() => {
          setRelocateChannelsStatus('initial');
        }, 3000); // in milliseconds.
      }
    });
  };

  return (
    <div>
      {relocateChannelsStatus === 'initial' && (
        <CustomButton
          button_text='Relocate'
          backgroundColor='#003366'
          type='accent'
          size='small'
          onClickFunction={() => {
            setRelocateChannelsStatus('relocating');
            relocateChannel();
          }}
        />
      )}
      {relocateChannelsStatus === 'relocating' && (
        <CustomButton
          isDisabled={true}
          button_text='Relocating...'
          type='tertiary'
          size='small'
          onClickFunction={() => {}}
        />
      )}
      {relocateChannelsStatus === 'success' && (
        <CustomButton
          isDisabled={true}
          button_text='Location updated'
          type='tertiary'
          size='small'
          onClickFunction={() => {}}
        />
      )}
      {relocateChannelsStatus === 'error' && (
        <CustomButton
          isDisabled={true}
          button_text='Something went wrong please try again.'
          type='tertiary'
          size='small'
          onClickFunction={() => {}}
        />
      )}
    </div>
  );
};
