import React from 'react';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { LookAroundNavbar } from '../navbar/LookAroundNavbar';

export const EnableLocation = () => {
  return (
    <div>
      <LookAroundNavbar />
      <ResponsiveBox
        children={[
          {
            leftChild: (
              <div>
                <h3 style={{ textAlign: 'center' }}>Enable location sharing</h3>
                LookAround needs to access your location to show/create channels. We always strive to share the minimum
                amount of data to provide a great expereince. Here are the steps to enable location sharing.
              </div>
            ),
          },
          {
            leftChild: <h4 style={{ marginTop: '10px' }}> iPhone or iPad </h4>,
          },
          {
            leftChild: (
              <div>
                <div>
                  <ol>
                    <li>Open the Settings app.</li>
                    <li>
                      <b>Tap Privacy and Security</b> &gt; <b>Location Services</b> &gt; <b>Chrome/Safari Websites</b>.
                    </li>
                    <li>
                      Under "Allow Location Access," tap <b>While Using the App</b>.
                    </li>
                  </ol>
                  Retry the operation that raised an error at <a href='https://www.lookaround.live'>LookAround.live</a>.
                  When prompted to share location, tap <b>Allow</b>.
                </div>
                <div>
                  If this does not work, proceed to the steps below:
                  <ol>
                    <li>Open the Settings app.</li>
                    <li>Tap General and then Reset.</li>
                    <li>Tap Reset Location & Privacy.</li>
                    <li>If asked, enter your passcode.</li>
                    <li>Tap Reset Settings.</li>
                    <li>Open Safari.</li>
                    <li>
                      Go to <a href='https://www.lookaround.live'>LookAround.live</a>
                    </li>
                    <li>To give Safari access to your location, tap Allow or OK.</li>
                    <li>To give Google Maps access to your location, tap OK.</li>
                  </ol>
                </div>
              </div>
            ),
          },
          {
            leftChild: <h4 style={{ marginTop: '10px' }}> Android phone or tablet </h4>,
          },
          {
            leftChild: (
              <div>
                <div>
                  <ol>
                    <li>Go to the Settings app.</li>
                    <li>Under "Personal," tap Location. (Depending upon your device, this may be found elsewhere.)</li>
                    <li>Turn on Location.</li>
                  </ol>
                  If there isn't an error after you open <a href='https://www.lookaround.live'>LookAround.live</a> and
                  there is a message about your device's location, tap Allow.
                </div>
                <div>
                  If you still get an error when you open <a href='https://www.lookaround.live'>LookAround.live</a>,
                  move on to the steps below.
                  <ol>
                    <li> Open the Chrome app Chrome.</li>
                    <li> In the top right, tap More.</li>
                    <li> Tap Settings. Under “Advanced,” tap Site settings.</li>
                    <li> Tap Location and turn on Location.</li>
                    <li>
                      {' '}
                      If you find "Location access is turned off for this device," tap the blue words and then on the
                      next screen, turn on location access.{' '}
                    </li>
                    <li>
                      {' '}
                      If you find "blocked" under "Location," tap Blocked. If LookAround is listed there, tap Clear &
                      reset.{' '}
                    </li>
                    <li>
                      {' '}
                      Open <a href='https://www.lookaround.live'>LookAround.live</a> in your mobile browser and reload
                      the page.
                    </li>
                  </ol>
                </div>
              </div>
            ),
          },
          {
            leftChild: (
              <div>
                <h3>Other devices and browsers</h3>
                If using other platforms, please reference the documentation for location sharing on that
                device/browser's website.
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
