import React, { useState, useEffect, useRef } from 'react';
import { LockedSymbol, UnlockedSymbol } from '../../svg/LockedSymbol';
import { utc_time_string_spoch_seconds_to_date, utc_time_string_to_local_time_string } from '../../utilities';
import { ClockSvg } from '../../svg/ClockSvg';
import { CircleRadiusSvg } from '../../svg/CircleRadiusSvg';

export const PrivacyNutritionLabel = ({ channelDetails }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');
  const popupRef = useRef(null);
  const buttonRef = useRef(null);

  const radius = '50';

  const toggleDetails = event => {
    setShowDetails(!showDetails);
    event.stopPropagation();
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDetails(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const endDateTime = utc_time_string_spoch_seconds_to_date(channelDetails.end_time).getTime();
      const now = new Date().getTime();
      const difference = endDateTime - now;

      if (difference <= 0) {
        return 'Closed';
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      // Display seconds only if less than 5 minutes are left
      if (difference < 300000) {
        // Less than 5 * 60 * 1000 milliseconds
        return `${minutes > 0 ? `${minutes}m` : ''}${seconds}s left`;
      } else if (days > 10) {
        return 'Open indefinitely';
      } else {
        return `${days > 0 ? `${days}d` : ''}${hours > 0 ? `${hours}h` : ''}${minutes > 0 ? `${minutes}m` : ''} left`;
      }
    };

    // Update the time left immediately and every second
    setTimeLeft(calculateTimeLeft());
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [channelDetails]);

  const popupStyle = {
    position: 'absolute',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    whiteSpace: 'nowrap',
    fontSize: '10px', // Reduced font size
    top: '30px',
    right: '0',
  };

  return (
    <div style={{ position: 'relative', alignContent: 'center', width: '100%', margin: '0 auto' }}>
      <div ref={buttonRef} onClick={toggleDetails} style={{ cursor: 'pointer' }}>
        <div
          className='nutritionLabel'
          style={{
            backgroundColor: timeLeft === 'Closed' ? 'rgba(211, 211, 211, 0.5)' : '#d4edda', // light grey with transparency
            padding: '2px',
            minWidth: '325px',
            border: timeLeft === 'Closed' ? '1px solid grey' : '1px solid darkgreen',
            borderRadius: '4px', // Rounded corners
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // Distributes children across the space
          }}
        >
          <span style={{ marginRight: '8px' }}>
            <ClockSvg dimension='20' />
            {timeLeft}
          </span>
          <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <span style={{ marginRight: '8px' }}>
              <CircleRadiusSvg dimension='20' />
              {radius}meters
            </span>
          </div>
          {channelDetails.has_passkey && (
            <span style={{ marginRight: '4px' }}>
              <LockedSymbol dimension='20' />
            </span>
          )}
          {!channelDetails.has_passkey && (
            <span style={{ marginRight: '4px' }}>
              <UnlockedSymbol dimension='20' />
            </span>
          )}
        </div>
      </div>
      {showDetails && (
        <div ref={popupRef} style={popupStyle}>
          <ul style={{ marginBottom: '0px', textAlign: 'left', paddingLeft: '20px', fontSize: '14px' }}>
            <li>Created by {channelDetails.creator}.</li>
            {channelDetails.channel_type !== 'curated_public' && channelDetails.channel_type !== 'curated_in_house' && (
              <li>Created at: {utc_time_string_to_local_time_string(channelDetails.creation_time)}.</li>
            )}
            {channelDetails.channel_type !== 'curated_public' && channelDetails.channel_type !== 'curated_in_house' && (
              <li>
                {timeLeft === 'Closed' ? 'Ended' : 'Ends'} at:{' '}
                {utc_time_string_to_local_time_string(channelDetails.end_time)}.
              </li>
            )}
            <li>{timeLeft === 'Closed' ? 'Was visible' : 'Visible'} only in a 50 meter radius.</li>
            {channelDetails.has_passkey && <li>Protected with a passkey.</li>}
            {!channelDetails.has_passkey && <li>Visible to everyone within the radius.</li>}
            <li>
              For any help, you can always reach us <a href={`mailto:siddharth@alum.mit.edu`}>here</a>.
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
