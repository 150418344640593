import React, { useState } from 'react';
import { CustomButton } from '../button/CustomButton';
import { closeChannel, fetchExperienceDetails } from '../../api/channel_api';

export const CloseChannel = ({ token, channelId, homepageCallback }) => {
  const [closeChannelRequestState, setCloseChannelRequestState] = useState('initial');

  const closeChannelCallback = async ({ token, channelId }) => {
    closeChannel(token, channelId).then(response => {
      if (response.statusCode === 200 || response.statusCode === 201) {
        setCloseChannelRequestState('success');
        // TODO: Handle this with webhooks and communicate to closure to all clients.
        fetchExperienceDetails(token, channelId).then(details => {
          if (details.statusCode === 400 || details.statusCode === 404) {
            homepageCallback({ callbackType: 'signout', payload: {} });
          } else if (details.statusCode === 200) {
            homepageCallback({ callbackType: 'channelDetails', payload: details });
          } else {
            throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
          }
        });
      } else {
        homepageCallback({ callbackType: 'signout', payload: {} });
      }
    });
  };

  return (
    <div>
      {closeChannelRequestState === 'initial' && (
        <CustomButton
          button_text='Close Now'
          onClickFunction={() => {
            setCloseChannelRequestState('closing');
            closeChannelCallback({ token, channelId });
          }}
          type='accent'
          size='small'
        />
      )}
      {closeChannelRequestState === 'closing' && <span style={{ color: 'white', fontSize: '14px' }}>Closing...</span>}
      {closeChannelRequestState === 'success' && (
        <span style={{ color: 'white', fontSize: '14px' }}>Channel closed.</span>
      )}
      {closeChannelRequestState === 'error' && (
        <span style={{ color: 'white', fontSize: '14px' }}>Something went wrong please try again</span>
      )}
    </div>
  );
};
