import React, { useState } from 'react';
import './Page.css';
import { ImageCarousel } from '../../channel_details/components/ImageCarousel';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { CustomButton } from '../../button/CustomButton';

export const Page = ({ page, title, handleEvent, pageIndex, location }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = index => {
    handleEvent({ eventType: 'page-answer', payload: `${pageIndex}_${index}` });
    setSelectedOption(index);
  };

  return (
    <div className='page' style={{ paddingLeft: '15px', paddingRight: '10px' }}>
      <h1 style={{ textAlign: 'center', paddingTop: '10px' }}>{title}</h1>
      <ResponsiveBox
        isSnug={true}
        children={[
          {
            leftChild: (
              <div className='carousel'>
                <ImageCarousel images={page.imageFilesS3} />
              </div>
            ),
          },
        ]}
      />
      {page.audioFileS3 && (
        <div className='audio'>
          <audio controls>
            <source src={page.audioFileS3} type='audio/mpeg' />
            Title of audio file.
          </audio>
        </div>
      )}
      {pageIndex === 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CustomButton
            size='small'
            type='tertiary'
            onClickFunction={() => {
              window.open(
                `https://maps.google.com/?q=${location.latitude},${location.longitude}`,
                '_blank',
                'noopener,noreferrer'
              );
            }}
            button_text='Directions'
          />{' '}
        </div>
      )}
      <div key='pageDescription' style={{ paddingTop: '20px' }}>
        {page.description.split('\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      {page.questionText && (
        <div style={{ paddingBottom: '20px' }}>
          <p style={{ marginTop: '1rem' }}>
            <strong>{page.questionText}</strong>
          </p>
          <div className='options'>
            {page.questionOptions.map(option => (
              <button
                key={option.index}
                onClick={() => handleOptionClick(option.index)}
                type='button'
                className={`
                option 
                ${selectedOption !== null && option.index === page.correctOptionIndex ? 'correct' : ''}
                ${selectedOption !== null && option.index === selectedOption && option.index !== page.correctOptionIndex ? 'incorrect' : ''}
                `}
              >
                {selectedOption !== null && option.index === page.correctOptionIndex ? '✔ ' : ''}
                {option.text}
              </button>
            ))}
          </div>
          {selectedOption !== null && (
            <div className='explanation'>
              <p>{page.correctOptionExplanation}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
