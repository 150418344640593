import React, { useState } from 'react';
import { CustomButton } from '../button/CustomButton';
import { getHistoricChannels } from '../../api/channel_api';

export const FindHistoricChannels = ({ homepageCallback, token }) => {
  const [retrieveHistoricChannelsStatus, setRetrieveHistoricChannelsStatus] = useState('initial');

  const getChannels = async () => {
    setRetrieveHistoricChannelsStatus('loading');
    getHistoricChannels(token).then(response => {
      if (response.statusCode !== 200) {
        setRetrieveHistoricChannelsStatus('error');
        homepageCallback({ callbackType: 'signout', payload: {} });
      } else {
        setRetrieveHistoricChannelsStatus('initial');
        homepageCallback({
          callbackType: 'timeline',
          payload: {
            timelineChannels: response.channels,
          },
        });
      }
    });
  };

  if (retrieveHistoricChannelsStatus === 'loading') {
    return <CustomButton onClickFunction={() => {}} button_text='Retrieving...' type='tertiary' size='small' />;
  } else if (retrieveHistoricChannelsStatus === 'initial') {
    return <CustomButton onClickFunction={getChannels} button_text='Timeline' type='tertiary' size='small' />;
  } else if (retrieveHistoricChannelsStatus === 'success') {
    return (
      <CustomButton onClickFunction={() => {}} button_text='Taking you to the timeline' type='tertiary' size='small' />
    );
  } else if (retrieveHistoricChannelsStatus === 'error') {
    return <p>Error fetching timeline, your session might have ended, please try refreshing.</p>;
  } else {
    throw new Error(`Unexpected state ${retrieveHistoricChannelsStatus}`);
  }
};
