import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { utc_time_string_to_hr_min_local_time_string } from '../../utilities';
import { CloseChannel } from '../CloseChannel';
import { CustomButton } from '../../button/CustomButton';
import { RelocateChannel } from '../RelocateChannel';

export const PanelForCreators = ({ channelDetails, token, homepageCallback }) => {
  const endDate = new Date(channelDetails.end_time * 1000);
  const currentDate = new Date();
  const channelIsOpen = endDate - currentDate >= 0;
  const panelFontStyle = { fontSize: '14px', color: '#333333', marginBottom: '0px', textAlign: 'center' };

  const children = [];
  !channelDetails.creator_is_premium &&
    children.push({
      leftChild: (
        <div style={panelFontStyle}>
          Try Premium to create channels that are open for longer and are visible further.
          <div style={{ marginTop: '5px', marginBottom: '10px' }}>
            <CustomButton
              button_text='Get Premium'
              type='accent'
              size='small'
              onClickFunction={() => {
                console.log('Want premium');
              }}
            />
          </div>
        </div>
      ),
    });
  if (channelIsOpen) {
    // channel is still open.
    children.push({
      leftChild: (
        <div style={panelFontStyle}>
          This channel closes at {utc_time_string_to_hr_min_local_time_string(channelDetails.end_time)}.
        </div>
      ),
      rightChild: <CloseChannel token={token} channelId={channelDetails.id} homepageCallback={homepageCallback} />,
      leftWidth: '69%',
      marginTop: '10px',
    });
    children.push({
      leftChild: (
        <div style={{ ...panelFontStyle }}>
          Relocate the channel if you move. Existing participants (those who have commented or joined using a key) can
          continue to view the channel.
        </div>
      ),
      rightChild: <RelocateChannel token={token} channelId={channelDetails.id} />,
      leftWidth: '70%',
      rightWidth: '30%',
      alignNaturally: false,
      marginTop: '7px',
    });
    children.unshift({ leftChild: <div style={{ textAlign: 'center' }}>Visible only to you.</div> });
  } else {
    // channel is closed.
    children.push({ leftChild: <div style={panelFontStyle}>Below is how the channel appeared to others.</div> });
    children.unshift({
      leftChild: (
        <div style={{ fontWeight: 'bold', fontStyle: 'italic', ...panelFontStyle }}>
          You created this channel and it is now closed.
        </div>
      ),
    });
  }
  return <ResponsiveBox backgroundColor='#F4F9F9' children={children} marginTopAndBottom='0px' />;
};
