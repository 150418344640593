import React, { useState } from 'react';
import './CreateChannelForm.css';
import { CustomButton } from '../button/CustomButton';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { ClockSvg } from '../svg/ClockSvg';
import { CircleRadiusSvg } from '../svg/CircleRadiusSvg';
import { createChannel, fetchExperienceDetails } from '../../api/channel_api';
import { LocationError, getGeolocation } from '../../api/location';

export const CreateChannelForm = ({ token, homepageCallback, isSubscribed }) => {
  const [channelName, setChannelName] = useState('');
  const [channelDescription, setChannelDescription] = useState('');
  const [channelType, setChannelType] = useState('');
  const [channelPasskey, setChannelPasskey] = useState('');
  const [channelTypeError, setChannelTypeError] = useState('');
  const [channelNameError, setChannelNameError] = useState('');
  const [channelDescriptionError, setChannelDescriptionError] = useState('');
  const [channelPasskeyError, setChannelPasskeyError] = useState('');
  const [channelCoverage, setChannelCoverage] = useState('50m_1h');
  const [retrieveLocationStatus, setRetrieveLocationStatus] = useState('initial');
  const [createChannelStatus, setCreateChannelStatus] = useState('initial');

  const retrieveLocationAndCreateChannel = ({
    channelName,
    channelDescription,
    channelType,
    channelPasskey,
    channelCoverage,
  }) => {
    setRetrieveLocationStatus('loading');
    getGeolocation()
      .then(locationResponse => {
        if (locationResponse.status === 'success') {
          // do something
          createChannel(
            token,
            {
              latitude: locationResponse.latitude,
              longitude: locationResponse.longitude,
              accuracy_meters: locationResponse.accuracy_meters,
            },
            channelName,
            channelDescription,
            channelType,
            channelPasskey,
            channelCoverage
          ).then(response => {
            if (response.statusCode === 409) {
              setChannelNameError(
                'At the moment there is another channel with the same name around you. Please choose a different name.'
              );
            } else if (response.statusCode === 401 || response.statusCode === 400) {
              homepageCallback({ callbackType: 'signout', payload: {} });
            } else if (response.statusCode === 200) {
              // Response is OK
              homepageCallback({ callbackType: 'loading', payload: {} });
              setCreateChannelStatus('initial');
              fetchExperienceDetails(token, response.channel_id).then(details => {
                if (details.statusCode === 400 || details.statusCode === 404) {
                  homepageCallback({ callbackType: 'signout', payload: {} });
                } else if (details.statusCode === 200) {
                  homepageCallback({ callbackType: 'channelDetails', payload: details });
                } else {
                  throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
                }
              });
            } else {
              throw new Error(`Error ${response.statusCode}: Something unexpected happened`);
            }
          });
        } else {
          setRetrieveLocationStatus('error');
        }
      })
      .catch(error => {
        setRetrieveLocationStatus('error');
      });
  };

  const validateChannelName = name => {
    if (name.length < 2) {
      return 'Channel name must be at least 2 characters long.';
    } else if (name.length > 50 || /\s{2,}/.test(name)) {
      return 'Should be between 2-50 characters, and not have consecutive spaces.';
    }
    return '';
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Validation logic
    setChannelName(channelName.trim());
    const channelNameError = validateChannelName(channelName.trim());
    if (channelNameError) {
      setChannelNameError(channelNameError);
      return;
    }
    if (channelDescription.length > 280) {
      setChannelDescriptionError('Channel description exceeds 280 characters limit.');
      return;
    }
    if (channelType === '') {
      setChannelTypeError('Select one');
      return;
    }
    if (channelPasskey.length > 20) {
      setChannelPasskeyError('Channel passkey exceeds 20 characters.');
      return;
    }
    // Form is valid, submit the data
    retrieveLocationAndCreateChannel({ channelName, channelDescription, channelType, channelPasskey, channelCoverage });
  };

  const handleChannelNameChange = e => {
    setChannelName(e.target.value);
    setChannelNameError('');
  };

  const handleChannelDescriptionChange = e => {
    setChannelDescription(e.target.value);
    setChannelDescriptionError('');
  };

  const handleChannelPasskeyChange = e => {
    setChannelPasskey(e.target.value.trim());
    setChannelPasskeyError('');
  };

  const handleCoverageChange = event => {
    setChannelCoverage(event.target.value);
  };

  const formChildren = [
    {
      leftChild: (
        <label
          htmlFor='channelName'
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginLeft: '10px',
          }}
        >
          Channel Name
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div style={{ marginBottom: '10px', marginLeft: '10px' }}>
          <input
            type='text'
            id='channelName'
            value={channelName}
            onChange={handleChannelNameChange}
            className='italic-placeholder'
            onBlur={() => {
              if (channelName.length > 50) {
                setChannelNameError('Channel name exceeds 50 characters limit.');
              }
            }}
            style={{
              width: '100%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
            }}
            placeholder='Up to 50 characters'
          />
          {channelNameError && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{channelNameError}</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <label
          htmlFor='channelDescription'
          style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', marginLeft: '10px' }}
        >
          Channel Description
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div style={{ marginBottom: '10px', marginLeft: '10px' }}>
          <textarea
            id='channelDescription'
            value={channelDescription}
            onChange={handleChannelDescriptionChange}
            className='italic-placeholder'
            onBlur={() => {
              if (channelDescription.length > 280) {
                setChannelDescriptionError('Channel description exceeds 280 characters limit.');
              }
            }}
            style={{
              width: '100%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
              fontFamily: 'inherit',
            }}
            placeholder='Optional, up to 280 characters'
          />
          {channelDescriptionError && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{channelDescriptionError}</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ marginBottom: '5px', marginLeft: '10px' }}>
          <div style={{ fontWeight: 'bold' }}>How should the channel function?</div>
          {setChannelTypeError && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{channelTypeError}</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <label
          style={{
            display: 'inline-block',
            marginRight: '10px',
            marginLeft: '5px',
            marginBottom: '6px',
          }}
        >
          <input
            type='radio'
            value='chat'
            checked={channelType === 'chat'}
            style={{ marginRight: '5px' }}
            onChange={() => {
              setChannelType('chat');
              setChannelTypeError('');
            }}
          />{' '}
          As a chat room.
        </label>
      ),
      alignNaturally: true,
    },
    {
      leftChild: (
        <label
          style={{
            display: 'inline-block',
            marginRight: '10px',
            marginLeft: '5px',
            marginBottom: '6px',
          }}
        >
          <input
            type='radio'
            value='inform'
            checked={channelType === 'inform'}
            style={{ marginRight: '5px' }}
            onChange={() => {
              setChannelType('inform');
              setChannelTypeError('');
            }}
          />{' '}
          As a notice board- others cannot comment.
        </label>
      ),
      alignNaturally: true,
    },
    {
      leftChild: (
        <label
          style={{
            display: 'inline-block',
            marginRight: '10px',
            marginLeft: '5px',
          }}
        >
          <input
            type='radio'
            value='poll'
            checked={channelType === 'poll'}
            style={{ marginRight: '5px' }}
            onChange={() => {
              setChannelType('poll');
              setChannelTypeError('');
            }}
          />{' '}
          As a form- only you can view comments.
        </label>
      ),
      alignNaturally: true,
    },
    {
      leftChild: (
        <div style={{ marginBottom: '5px', fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
          Would you like to set a passcode for the channel? (optional)
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            type='text'
            id='channelPasskey'
            value={channelPasskey}
            onChange={handleChannelPasskeyChange}
            className='italic-placeholder'
            style={{
              width: '60%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
            }}
            placeholder='Upto 20 characters, no spaces.'
          />
          {channelPasskeyError && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{channelPasskeyError}</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ marginBottom: '0px', marginTop: '10px' }}>
          <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Select the radius <CircleRadiusSvg dimension='20' /> and duration <ClockSvg dimension='20' /> for the
            channel.
          </div>
          {!isSubscribed && (
            <div
              style={{
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '5px',
              }}
            >
              <CustomButton
                onClickFunction={() => homepageCallback({ callbackType: 'subscribe', payload: {} })}
                button_text='Do more with LookAround+'
                type='accent'
                size='small'
              />
            </div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
          <select
            value={channelCoverage}
            onChange={handleCoverageChange}
            name='channelCoverage'
            id='channelCoverage'
            className='italic-placeholder'
            style={{
              width: '60%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
              marginBottom: '0px',
            }}
          >
            <option value='50m_1h'>50 meters, 1 hour</option>
            {isSubscribed ? (
              <option value='50m_3h'>50 meters, 3 hours </option>
            ) : (
              <option value='50m_3h' disabled>
                50 meters, 3 hours (LookAround+)
              </option>
            )}
            {isSubscribed ? (
              <option value='300m_1h' disabled>
                300 meters, 1 hour (coming soon)
              </option>
            ) : (
              <option value='300m_1h' disabled>
                300 meters, 1 hour (coming soon to LookAround+)
              </option>
            )}
          </select>
        </div>
      ),
    },
    {
      leftChild: (
        <div>
          <p
            style={{
              marginBottom: '0px',
              marginTop: '5px',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            📍LookAround will request for location access to create the channel.
          </p>
          {retrieveLocationStatus === 'error' && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px', textAlign: 'center' }}>
              <LocationError />
            </div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
          <CustomButton button_text='Create' backgroundColor='#2E8B57' onClickFunction={() => {}} type='primary' />
        </div>
      ),
    },
  ];

  if (retrieveLocationStatus === 'loading') {
    return (
      <ResponsiveBox
        isSnug={true}
        children={[{ leftChild: <p style={{ textAlign: 'center' }}>Getting location, will take a few seconds...</p> }]}
      />
    );
  } else if (createChannelStatus === 'creating') {
    return (
      <ResponsiveBox
        isSnug={true}
        children={[{ leftChild: <p style={{ textAlign: 'center' }}>Creating channel...</p> }]}
      />
    );
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <ResponsiveBox children={formChildren} />
      </form>
    );
  }
};
