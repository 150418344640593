import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, FeatureGroup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { v4 as uuidv4 } from 'uuid'; // Import UUID

// Remove default icon URLs to avoid broken image links
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

const MapInitializer = ({
  initialPolygons,
  handlePolygonClick,
  setInternalPolygons,
  isDeletingRef,
  featureGroupRef,
}) => {
  const map = useMap();
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      const uniqueIds = new Set();
      const newPolygons = initialPolygons.map(polygon => {
        if (uniqueIds.has(polygon.id)) {
          console.error(`Duplicate ID detected: ${polygon.id}. Generating a new unique ID.`);
          polygon.id = uuidv4();
        }
        uniqueIds.add(polygon.id);

        const layer = L.polygon(polygon.coordinates).addTo(featureGroupRef.current);
        const updatedPolygon = { ...polygon, leafletLayer: layer };
        layer.on('click', () => handlePolygonClick(layer));
        return updatedPolygon;
      });

      setInternalPolygons(newPolygons);
      featureGroupRef.current.addTo(map);
      initializedRef.current = true;

      map.on('draw:deletestart', () => {
        isDeletingRef.current = true;
        map.eachLayer(layer => {
          if (layer instanceof L.Polygon) {
            layer.off('click');
          }
        });
      });

      const reattachClickHandlers = () => {
        isDeletingRef.current = false;
        map.eachLayer(layer => {
          if (layer instanceof L.Polygon) {
            const polygon = newPolygons.find(p => p.leafletLayer === layer);
            if (polygon) {
              layer.on('click', () => handlePolygonClick(layer));
            }
          }
        });
      };

      map.on('draw:deleteend', reattachClickHandlers);
      map.on('draw:deletestop', reattachClickHandlers);
    }
  }, [initialPolygons, handlePolygonClick, map, setInternalPolygons, isDeletingRef, featureGroupRef]);

  return null;
};

export const MapComponent = ({ initialPolygons, setPolygons, editable = true }) => {
  const mapCenter =
    initialPolygons.length === 0 ? { lat: 37.80084, lng: -122.40986 } : initialPolygons[0]['coordinates'][0];
  const [center] = useState(mapCenter);
  const ZOOM_LEVEL = 15;
  const [internalPolygons, setInternalPolygons] = useState([]);
  const isDeletingRef = useRef(false);
  const featureGroupRef = useRef(L.featureGroup());
  const polygonsRef = useRef(internalPolygons);

  useEffect(() => {
    polygonsRef.current = internalPolygons;
  }, [internalPolygons]);

  // Update the parent state whenever the internal polygons state changes
  useEffect(() => {
    const sanitizedPolygons = internalPolygons.map(({ leafletLayer, ...rest }) => rest);
    setPolygons(sanitizedPolygons);
  }, [internalPolygons, setPolygons]);

  // Handle creation of a new polygon
  const handleCreated = e => {
    const layer = e.layer;
    const coordinates = layer.getLatLngs()[0];
    const name = prompt('Enter a name for the polygon:');
    if (name) {
      const newPolygon = { id: uuidv4(), name, coordinates, leafletLayer: layer }; // Use UUID for new IDs
      layer.on('click', () => handlePolygonClick(layer));
      setInternalPolygons(prevPolygons => [...prevPolygons, newPolygon]);
      layer.addTo(featureGroupRef.current);
    }
  };

  // Handle editing of polygons
  const handleEdited = e => {
    const layers = e.layers;
    const updatedPolygons = [];
    layers.eachLayer(layer => {
      // TODO: does isFlat ever evaluate to true?
      const coordinates = L.LineUtil.isFlat(layer.getLatLngs()) ? [layer.getLatLngs()] : layer.getLatLngs();
      const updatedPolygon = { coordinates, leafletLayer: layer };
      updatedPolygons.push(updatedPolygon);
    });
    setInternalPolygons(prevPolygons =>
      prevPolygons.map(polygon => {
        const updatedPolygon = updatedPolygons.find(p => p.leafletLayer === polygon.leafletLayer);
        const newPolygon = {
          id: polygon.id,
          name: polygon.name,
          leafletLayer: polygon.leafletLayer,
          coordinates: updatedPolygon.coordinates[0],
        };
        return updatedPolygon ? newPolygon : polygon;
      })
    );
  };

  // Handle deletion of polygons
  const handleDeleted = e => {
    const layers = e.layers;
    const deletedIds = [];
    layers.eachLayer(layer => {
      deletedIds.push(layer._leaflet_id);
    });
    setInternalPolygons(prevPolygons =>
      prevPolygons.filter(polygon => !deletedIds.includes(polygon.leafletLayer._leaflet_id))
    );
    setTimeout(() => {
      isDeletingRef.current = false;
    }, 0);
  };

  // Handle polygon click to rename
  const handlePolygonClick = useCallback(layer => {
    const polygons = polygonsRef.current;
    const polygon = polygons.find(p => p.leafletLayer === layer);
    const currentName = polygon ? polygon.name : '';
    const newName = prompt(`Enter a new name for ${currentName}:`, currentName);
    if (newName) {
      setInternalPolygons(prevPolygons =>
        prevPolygons.map(polygon => {
          if (polygon.leafletLayer === layer) {
            return { ...polygon, name: newName };
          }
          return polygon;
        })
      );
    }
  }, []);

  return (
    <div style={{ height: '80vh', width: '90%' }}>
      <MapContainer center={center} zoom={ZOOM_LEVEL} style={{ height: '100%' }}>
        <MapInitializer
          initialPolygons={initialPolygons}
          handlePolygonClick={editable ? handlePolygonClick : () => {}}
          setInternalPolygons={setInternalPolygons}
          isDeletingRef={isDeletingRef}
          featureGroupRef={featureGroupRef}
        />
        {editable && (
          <FeatureGroup ref={featureGroupRef}>
            <EditControl
              position='topright'
              onCreated={handleCreated}
              onEdited={handleEdited}
              onDeleted={handleDeleted}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
              }}
            />
          </FeatureGroup>
        )}
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </MapContainer>
    </div>
  );
};

MapComponent.propTypes = {
  initialPolygons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  setPolygons: PropTypes.func.isRequired,
};
