import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Navbar.css';

export const SignedInNavbar = ({ homepageCallback, isSubscribed = true, email = '', showBrand = true }) => {
  const handleClick = (actionType, payload = {}) => {
    homepageCallback({ callbackType: actionType, payload });
  };

  // const handleManagePayments = () => {
  //   const encodedEmail = encodeURIComponent(email);
  //   const baseUrl = 'https://billing.stripe.com/p/login/9AQ7uKeAY2IL0QE000';
  //   const finalUrl = `${baseUrl}?prefilled_email=${encodedEmail}`;
  //   window.location.href = finalUrl;
  // };

  return (
    <Navbar collapseOnSelect expand='lg' className='custom-navbar'>
      <Container>
        <Navbar.Brand href='/'> {showBrand ? 'LookAround' : ''}</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='ms-auto'>
            <Nav.Link href='/'>Home</Nav.Link>
            {/* {!isSubscribed && <Nav.Link onClick={() => handleClick('subscribe')}>LookAround+</Nav.Link>} */}
            {/* <Nav.Link onClick={handleManagePayments}>Transactions</Nav.Link> */}
            <Nav.Link onClick={() => handleClick('signout')}>Sign out</Nav.Link>
            <Nav.Link href='/about'>About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
