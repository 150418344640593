import React from 'react';
import './ResponsiveBox.css';

export const ResponsiveBox = ({ children, isSnug = false, backgroundColor = 'white', marginTopAndBottom = '10px' }) => {
  const boxClassName = isSnug ? 'responsive-box snug' : 'responsive-box';
  return (
    <div
      className={boxClassName}
      style={{
        backgroundColor: backgroundColor,
        marginTop: marginTopAndBottom,
        marginBottom: marginTopAndBottom,
      }}
    >
      {children.map((row, index) => {
        const [leftWidth, rightWidth] = parseWidths(row.leftWidth, row.rightWidth, row.leftChild, row.rightChild);
        const rowWidth = row.width ? row.width : '100%';
        return (
          <div
            key={index}
            className={`row`}
            style={{
              width: rowWidth,
              margin: '0 auto',
              marginTop: row.marginTop ? row.marginTop : undefined,
            }}
          >
            {row.leftChild && (
              <div
                className='left'
                style={{
                  flex: `0 0 ${leftWidth}`,
                  alignContent: `${row.alignNaturally ? 'left' : 'center'}`,
                  padding: '0px',
                }}
              >
                {row.leftChild}
              </div>
            )}
            {row.rightChild && (
              <div
                className='right'
                style={{
                  flex: `0 0 ${rightWidth}`,
                  alignContent: `${row.alignNaturally ? 'right' : 'center'}`,
                  padding: '0px',
                }}
              >
                {row.rightChild}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const parseWidths = (leftWidth, rightWidth, hasLeftChild, hasRightChild) => {
  if (leftWidth && rightWidth) {
    // If both widths are provided, assert their sum is 100%
    const sum = parseFloat(leftWidth) + parseFloat(rightWidth);
    if (sum !== 100) {
      throw new Error(`The sum of ${leftWidth} and ${rightWidth} should be 100%`);
    }
    return [leftWidth, rightWidth];
  } else if (leftWidth && !rightWidth) {
    // If only leftWidth is provided, calculate rightWidth
    return [leftWidth, computeOtherPercentage(leftWidth)];
  } else if (!leftWidth && rightWidth) {
    // If only rightWidth is provided, calculate leftWidth
    return [computeOtherPercentage(rightWidth), rightWidth];
  } else {
    if (hasLeftChild && !hasRightChild) {
      return ['100%', '0%'];
    } else if (!hasLeftChild && hasRightChild) {
      return ['0%', '100%'];
    } else {
      return ['50%', '50%'];
    }
  }
};

const computeOtherPercentage = percentageString => {
  const floatPercentage = parseFloat(percentageString);
  if (floatPercentage > 100) {
    throw new Error(`leftWidth ${floatPercentage} > 100`);
  }
  return `${100 - floatPercentage}%`;
};
