import { PostComment } from './PostComment';
import { UploadPicture } from './UploadPicture';

export const getNewParticipationSection = ({ homepageCallback, token, channelDetails, handleNewComment }) => {
  const children = [];
  if (
    channelDetails.user_is_creator ||
    // The channel owner can comment at any time. If channel is "chat" or "poll"
    // and is still open, allow users to comment.
    ((channelDetails.channel_type === 'chat' || channelDetails.channel_type === 'poll') &&
      new Date(channelDetails.end_time * 1000) - new Date() >= 0)
  ) {
    children.push(
      ...UploadPicture({
        homepageCallback: homepageCallback,
        token: token,
        channelId: channelDetails.id,
        handleNewComment: handleNewComment,
      })
    );
    children.push(
      ...PostComment({
        token: token,
        channelId: channelDetails.id,
        handleNewComment: handleNewComment,
        channelType: channelDetails.channel_type,
        creatorName: channelDetails.creator,
      })
    );
  }
  return children;
};
