import { asyncPostRequest } from './requests';

export const fetchChannelDetails = async (token, channel_id, enteredPasskey = '') => {
  // TODO: Verify details here
  return await asyncPostRequest(
    {
      token: token,
      channel_id: channel_id,
      passkey: enteredPasskey,
    },
    '/channels/channel_details'
  );
};

export const fetchExperienceDetails = async (token, experience_id) => {
  // TODO: Verify details here
  return await asyncPostRequest({ token, experience_id }, '/experiences/experience_details');
};

export const postLocationAccessDenied = async token => {
  return await asyncPostRequest(token === '' ? {} : { token }, '/event/location_access_denied');
};

export const createChannel = async (
  token,
  location,
  channelName,
  channelDescription,
  channelType,
  channelPasskey,
  channelCoverage
) => {
  // TODO: verify channel details
  // TODO: Add docs for response codes.
  return await asyncPostRequest(
    {
      token: token,
      location: {
        latitude: location.latitude,
        longitude: location.longitude,
        accuracy_meters: location.accuracy_meters,
      },
      channel_name: channelName,
      channel_description: channelDescription,
      channel_type: channelType,
      passkey: channelPasskey,
      channel_coverage: channelCoverage,
    },
    '/channels/create_channel'
  );
};

export const createExperience = async ({
  token,
  location,
  name,
  channelDescription,
  isPremium,
  thumbnailFileS3,
  references,
  pages,
}) => {
  return await asyncPostRequest(
    {
      token: token,
      channel_name: name,
      channel_description: channelDescription,
      location: location,
      is_premium: isPremium,
      thumbnail_file_name_s3: thumbnailFileS3,
      references: references,
      pages: pages.map(page => {
        return {
          index: page.index,
          description: page.description,
          audio_file_name_s3: page.audioFileNameS3,
          question: page.questionText,
          question_options: page.questionOptions.map(questionOption => questionOption.text),
          correct_option_index: page.correctOptionIndex,
          correct_option_explanation: page.correctOptionExplanation,
          image_file_names_s3: page.imageFilesS3.map(imageFile => imageFile.text),
        };
      }),
    },
    '/experiences/create_experience'
  );
};

export const getHistoricChannels = async token => {
  return await asyncPostRequest({ token: token }, '/channels/rated_channels');
};

export const getExperiences = async (token, latitude, longitude) => {
  return await asyncPostRequest(
    {
      token: token,
      location: {
        latitude: latitude,
        longitude: longitude,
      },
    },
    '/channels/lookaround'
  );
};

export const getPublicExperiences = async (latitude, longitude) => {
  return await asyncPostRequest(
    {
      location: {
        latitude: latitude,
        longitude: longitude,
      },
    },
    '/channels/lookaround_public'
  );
};

export const getDetailsAndNearbyExperiences = async (token, latitude, longitude, experienceTitle) => {
  return await asyncPostRequest(
    {
      token: token,
      location: {
        latitude: latitude,
        longitude: longitude,
      },
      experience_title: experienceTitle,
    },
    '/experiences/details_and_nearby_experiences'
  );
};

export const getExperiencePoints = async token => {
  return await asyncPostRequest({ token: token }, '/experiences/experience_points');
};

export const updateChannelLocation = async (token, channelId, latitude, longitude, accuracyMeters) => {
  return await asyncPostRequest(
    {
      token: token,
      latitude: latitude,
      longitude: longitude,
      channel_id: channelId,
      accuracy_meters: accuracyMeters,
    },
    '/channels/update_location'
  );
};

export const closeChannel = async (token, channelId) => {
  return await asyncPostRequest(
    {
      token: token,
      channel_id: channelId,
    },
    '/channels/close_channel'
  );
};

export const resetExperienceRatingsForAlex = async token => {
  return await asyncPostRequest({ token: token }, 'experiences/reset_experiences_for_alex');
};

export const resetExperienceRatingsForJane = async token => {
  return await asyncPostRequest({ token: token }, 'experiences/reset_experiences_for_jane');
};
