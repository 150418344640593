import React, { useState } from 'react';
import './CustomButton.css';

export const CustomButton = ({
  onClickFunction,
  button_text,
  type = 'primary', // 'primary', 'secondary', 'tertiary', 'accent' or 'plain'.
  size, // blank, 'large' or 'small'
  isSubmit = false,
  disabled = false,
  disabledClickMessage = null,
  expand = false,
}) => {
  const [clickedWhileDisabled, setClickedWhileDisabled] = useState(false);
  const buttonClass = `custom-button 
    ${size ? `custom-button-${size}` : ''} 
    custom-button-${type} 
    ${disabled && 'custom-button-disabled'} 
    ${clickedWhileDisabled && 'custom-button-clicked-disabled'}
  `;

  const handleClick = event => {
    if (disabled) {
      event.preventDefault();
      setClickedWhileDisabled(true);
      if (disabledClickMessage !== null) {
        setTimeout(() => {
          setClickedWhileDisabled(false);
        }, 2000); // Reset after 500ms
      }
    } else {
      onClickFunction(event);
    }
  };

  return (
    <div
      className='button-wrapper'
      onClick={handleClick}
      style={{
        display: 'flex',
        cursor: disabled ? 'not-allowed' : 'pointer',
        width: '100%',
        justifyContent: 'center',
      }}
      // display: 'flex', justifyContent: 'center' }}>
    >
      <button
        className={buttonClass}
        type={isSubmit ? 'submit' : 'button'}
        disabled={disabled}
        style={
          disabled
            ? {
                pointerEvents: 'none',
                width: expand ? '100%' : 'auto',
              }
            : {
                width: expand ? '100%' : 'auto',
              }
        }
      >
        {clickedWhileDisabled ? disabledClickMessage : button_text}
      </button>
    </div>
  );
};
