import React from 'react';

export const LockedSymbol = ({ dimension = '24' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='5 5 30 30'
      width={dimension}
      height={dimension}
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <rect x='11' y='17' width='15' height='11' rx='2' ry='2'></rect>
      <path d='M14 17V13a5 5 0 0 1 9 0v4'></path>
    </svg>
  );
};

export const UnlockedSymbol = ({ dimension = '24' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='5 5 30 30'
      width={dimension}
      height={dimension}
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <rect x='11' y='17' width='15' height='11' rx='2' ry='2'></rect>
      <path d='M6 17V12a5 5 0 0 1 9 0v5'></path>
    </svg>
  );
};
