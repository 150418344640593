import React, { useState } from 'react';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { CustomButton } from '../../button/CustomButton';

export const PostComment = ({ token, channelId, handleNewComment, channelType, creatorName }) => {
  const [commentText, setCommentText] = useState('');
  const [commentTextError, setCommentTextError] = useState('');
  const [postCommentStatus, setPostCommentStatus] = useState('initial');

  const handleCommentTextChange = e => {
    setCommentText(e.target.value);
    setCommentTextError('');
  };

  const postComment = async ({ commentText }) => {
    handleNewComment({
      token: token,
      channel_id: channelId,
      message: commentText,
    });
    setPostCommentStatus('initial');
    setCommentText('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Validation logic
    setCommentText(commentText.trim());
    if (commentText.length > 280) {
      setCommentTextError('Channel description exceeds 280 characters limit.');
      return;
    }
    // Form is valid, submit the data
    setPostCommentStatus('posting');
    postComment({ commentText });
  };

  //   GetChannelDetails
  if (postCommentStatus === 'initial') {
    return [
      {
        leftChild: (
          <form onSubmit={handleSubmit}>
            <textarea
              id='commentText'
              value={commentText}
              onChange={handleCommentTextChange}
              className='italic-placeholder'
              style={{
                width: '100%',
                padding: '5px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxSizing: 'border-box',
                fontSize: '14px',
                marginTop: '5px',
                fontFamily: 'inherit',
              }}
              placeholder={`Up to 280 characters ${
                channelType === 'chat'
                  ? '\n(Visible to all participants)'
                  : channelType === 'poll'
                    ? `\n(Visible only to ${creatorName})`
                    : ''
              }`}
            />
            {commentTextError && <span style={{ color: 'red', fontSize: '12px' }}>{commentTextError}</span>}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CustomButton button_text='Comment' onClickFunction={() => {}} type='primary' />
            </div>
          </form>
        ),
      },
    ];
  } else if (postCommentStatus === 'posting') {
    return [
      {
        leftChild: <ResponsiveBox isSnug={true} children={[{ leftChild: <p>Posting...</p> }]} />,
        alignNaturally: true,
      },
    ];
  }
};
